import { useState, useMemo, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useObservable } from '@ngneat/react-rxjs'

import {
  ManageMaterial,
  MaterialType,
  Unit,
  UnitWithoutImperials,
  MaterialQuality,
  DimensionUnit,
  DimensionUnitWithoutImperials,
  TermsOfSale,
  MaterialState,
} from '../../models/materials.models'
import { FormItem, ItemType } from '../../models/props.models'
import { RetrievalModality, Route, Currency } from '../../models/commons.models'
import { sessionService } from '../../store/session'
import { materialModelsQuery } from '../../store/materialModels'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { ObjectUtils, StringUtils } from '../../utils/commons.utils'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import MaterialTableQuantities from './TableQuantities.material'
import Constants from '../../constants'
import useRoute from '../../hooks/useRoute.hooks'
import InputCategory from '../category/Input.category'

const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

interface ModalFormMaterialProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'tabs' | 'onDelete' | 'onTransfer'> {
  material: Partial<ManageMaterial>
  onDelete?: (materialId: string) => void
  onTransfer?: (materialModelId: string) => void
  onDuplicate?: (materialModelId: string) => void
}
const ModalFormMaterial: React.FC<ModalFormMaterialProps> = (props) => {
  const { t } = useTranslation()
  const { goTo } = useRoute()

  const { material, onDelete, onTransfer, onDuplicate, ...formProps } = props
  const [value, setValue] = useState<ManageMaterial>({
    sellByQuantityOf: 1,
    minQuantity: 0,
    ...material,
  } as ManageMaterial)
  // it won't update
  const isCreationRef = useRef(!value?._id)
  const isNeedRef = useRef(value?.type === MaterialType.need)
  const showPublicRef = useRef(sessionService.showPublic(value?.type))

  const [materialModels] = useObservable(materialModelsQuery.materialModels)
  const materialModelOptions = useMemo(
    () =>
      materialModels.map((materialModel) => ({
        label: `${materialModel.name} (${t(
          `categories:name.${materialModel.primaryCategory.name}` as any,
        )})`,
        value: materialModel._id,
        data: materialModel,
      })),
    [t, materialModels],
  )

  const handleSetValue = useCallback(
    (updateValue: (currentValue: ManageMaterial) => ManageMaterial) => {
      setValue((currentValue) => {
        const val = updateValue(currentValue)

        if ((val as any)?.materialModel) {
          const { _id, ...materialModelData } = ObjectUtils.copy((val as any).materialModel)
          return {
            ...val,
            ...materialModelData,
            materialModel: undefined,
          }
        }

        return val
      })
    },
    [],
  )

  const tabs: { title: string; items: FormItem[] }[] = useMemo(
    () => [
      {
        title: t('materials:components.modalForm.details'),
        items: [
          {
            type: ItemType.group,
            key: 'materialModelGroup',
            props: {
              title: t('materials:components.modalForm.materialModel'),
            },
            hideItem: !isCreationRef.current || materialModelOptions.length === 0,
            items: [
              {
                type: ItemType.asyncSelect,
                key: 'materialModel',
                props: {
                  searchIfEmpty: true,
                  placeholder: t('global:inputs.selectPlaceholder'),
                  dataValue: '_id',
                  dataLabel: 'name',
                  options: materialModelOptions,
                },
              },
            ],
          },
          {
            type: ItemType.group,
            key: 'global',
            props: {
              title: t('materials:components.modalForm.global'),
            },
            grid: { xs: 12 },
            items: [
              {
                type: ItemType.group,
                grid: { xs: 12, sm: 6 },
                key: 'main',
                items: [
                  {
                    type: ItemType.text,
                    key: 'name',
                    required: true,
                    props: {
                      label: t('materials:attributes.name'),
                      placeholder: t('materials:attributes.name'),
                    },
                  },
                  {
                    type: ItemType.custom,
                    key: 'tertiaryCategory',
                    required: true,
                    custom: (
                      <InputCategory
                        label={t('materials:attributes.category')}
                        placeholder={t('materials:attributes.category')}
                        required
                        accurateCategory
                        primaryCategory={value.primaryCategory}
                        secondaryCategory={value.secondaryCategory}
                        tertiaryCategory={value.tertiaryCategory}
                        onChange={(categoryValue: any) => {
                          setValue((val: any) => ({
                            ...val,
                            ...categoryValue,
                          }))
                        }}
                      />
                    ),
                  },
                  {
                    type: ItemType.select,
                    key: 'unit',
                    required: true,
                    props: {
                      label: t('materials:attributes.unit'),
                      placeholder: t('global:inputs.selectPlaceholder'),
                      items: createOptionsFromEnum(
                        sessionService.getCountryParam('useImperialUnits')
                          ? Unit
                          : UnitWithoutImperials,
                        'materials:unit',
                      ),
                    },
                  },
                  {
                    type: ItemType.text,
                    key: 'reference',
                    hideItem: isCreationRef.current,
                    props: {
                      readOnly: true,
                      label: t('materials:attributes.reference'),
                    },
                  },
                  {
                    type: ItemType.checkbox,
                    key: 'visible',
                    hideItem: !showPublicRef.current,
                    formatValue: (val) => val !== false,
                    props: {
                      label: t('materials:attributes.visible'),
                    },
                  },
                ],
              },
              (material: ManageMaterial) => ({
                type: ItemType.image,
                grid: { xs: 12, sm: 6 },
                key: 'mainImageFileFile',
                required: !isNeedRef.current,
                props: {
                  label: t('materials:components.modalForm.addPicture'),
                  maxFileSize: 2.5,
                  fileDetails: material.mainImageFile,
                },
              }),
              (material: ManageMaterial) => ({
                type: ItemType.link,
                key: 'originalCatalog',
                grid: { xs: 6, md: 3 },
                hideItem: !material.originalCatalog,
                props: {
                  children: t('materials:components.modalForm.originalCatalog', {
                    catalog: material?.originalCatalog?.name,
                  }),
                  onClick: () => {
                    if (material?.originalCatalog?._id) {
                      goTo({
                        route: Route.workspaceCatalog,
                        catalogId: material.originalCatalog._id,
                      })
                    }
                  },
                },
              }),
              {
                type: ItemType.number,
                key: 'initialQty',
                grid: { xs: 6, md: 3 },
                rules: [YupUtils.FieldValidationType.positive],
                hideItem: !isNeedRef.current,
                props: {
                  label: t('materials:attributes.desiredQty'),
                  placeholder: t('materials:attributes.desiredQty'),
                },
              },
              {
                type: ItemType.date,
                grid: { xs: 6, md: 3 },
                key: 'retrieval.endDate',
                hideItem: !isNeedRef.current,
                props: {
                  label: t('materials:attributes.needsEndDate'),
                },
              },
              {
                type: ItemType.select,
                grid: { xs: 6, md: 3 },
                key: 'quality',
                hideItem: !isNeedRef.current,
                props: {
                  label: t('materials:attributes.minimumQuality'),
                  placeholder: t('global:inputs.selectPlaceholder'),
                  items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
                },
              },
              {
                type: ItemType.checkbox,
                grid: { xs: 6, md: 3 },
                key: 'uniqueDeposit',
                hideItem: !isNeedRef.current,
                props: {
                  label: t('materials:attributes.uniqueDeposit'),
                },
              },

              {
                type: ItemType.text,
                key: 'description',
                hideItem: !isNeedRef.current,
                props: {
                  label: t('materials:attributes.observations'),
                  multiline: true,
                  minRows: 2,
                },
              },
            ] as FormItem[],
          },
          {
            type: ItemType.group,
            key: 'observationsGroup',
            hideItem: isNeedRef.current,
            props: {
              accordion: true,
              title: t('materials:attributes.observations'),
            },
            items: [
              {
                type: ItemType.text,
                key: 'description',
                props: {
                  label: t('materials:attributes.description'),
                  multiline: true,
                  minRows: 2,
                },
              },
              {
                type: ItemType.text,
                key: 'privateDescription',
                props: {
                  label: t('materials:attributes.privateDescription'),
                  multiline: true,
                  minRows: 2,
                },
              },
            ],
          },
          {
            type: ItemType.group,
            key: 'characteristic',
            hideItem: isNeedRef.current,
            props: {
              accordion: true,
              title: t('materials:components.modalForm.characteristic'),
            },
            items: [
              (material: ManageMaterial) => ({
                type: ItemType.group,
                key: 'dimensionsGroup',
                props: {
                  title: t('materials:attributes.dimensions.title'),
                },
                items: [
                  {
                    type: ItemType.select,
                    grid: { xs: 6, sm: 4, md: 3 },
                    key: 'dimensions.unit',
                    props: {
                      label: t('materials:attributes.dimensions.unit'),
                      placeholder: t('global:inputs.selectPlaceholder'),
                      items: createOptionsFromEnum(
                        sessionService.getCountryParam('useImperialUnits')
                          ? DimensionUnit
                          : DimensionUnitWithoutImperials,
                        'materials:dimensionUnit',
                      ),
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 4, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'dimensions.length',
                    props: {
                      label: t('materials:attributes.dimensions.length'),
                      placeholder: t('materials:attributes.dimensions.length'),
                      endAdornment: material.dimensions?.unit ? (
                        <EndAdornmentLabel>
                          {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 4, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'dimensions.width',
                    props: {
                      label: t('materials:attributes.dimensions.width'),
                      placeholder: t('materials:attributes.dimensions.width'),
                      endAdornment: material.dimensions?.unit ? (
                        <EndAdornmentLabel>
                          {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 4, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'dimensions.height',
                    props: {
                      label: t('materials:attributes.dimensions.height'),
                      placeholder: t('materials:attributes.dimensions.height'),
                      endAdornment: material.dimensions?.unit ? (
                        <EndAdornmentLabel>
                          {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 4, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'dimensions.depth',
                    props: {
                      label: t('materials:attributes.dimensions.depth'),
                      placeholder: t('materials:attributes.dimensions.depth'),
                      endAdornment: material.dimensions?.unit ? (
                        <EndAdornmentLabel>
                          {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 4, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'dimensions.thickness',
                    props: {
                      label: t('materials:attributes.dimensions.thickness'),
                      placeholder: t('materials:attributes.dimensions.thickness'),
                      endAdornment: material.dimensions?.unit ? (
                        <EndAdornmentLabel>
                          {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 4, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'dimensions.diameter',
                    props: {
                      label: t('materials:attributes.dimensions.diameter'),
                      placeholder: t('materials:attributes.dimensions.diameter'),
                      endAdornment: material.dimensions?.unit ? (
                        <EndAdornmentLabel>
                          {t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                ],
              }),
              {
                type: ItemType.group,
                key: 'technicalDetailsGroup',
                props: {
                  title: t('materials:attributes.technicalDetails.title'),
                },
                items: [
                  {
                    type: ItemType.array,
                    key: 'technicalDetails',
                    props: {
                      orderable: true,
                      addLabel: t('materials:attributes.technicalDetails.addButton'),
                    },
                    items: [
                      {
                        type: ItemType.text,
                        grid: { xs: 12, sm: 6 },
                        key: 'name',
                        required: true,
                        props: {
                          label: t('materials:attributes.technicalDetails.name'),
                          placeholder: t('materials:attributes.technicalDetails.name'),
                        },
                      },
                      {
                        type: ItemType.text,
                        grid: { xs: 12, sm: 6 },
                        key: 'value',
                        props: {
                          label: t('materials:attributes.technicalDetails.value'),
                          placeholder: t('materials:attributes.technicalDetails.value'),
                        },
                      },
                    ] as FormItem[],
                  },
                ],
              },
              (material: ManageMaterial) => ({
                type: ItemType.group,
                key: 'weight',
                props: {
                  title: t('materials:attributes.weight.title'),
                },
                items: [
                  {
                    type: ItemType.number,
                    grid: { xs: 6, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'unitWeight',
                    props: {
                      label: t('materials:attributes.weight.unit'),
                      placeholder: t('materials:attributes.weight.unit'),
                      endAdornment: material?.unit ? (
                        <EndAdornmentLabel>
                          {t(`materials:attributes.weight.weightByUnit`, {
                            unit: t(`materials:unitSymbol.${material.unit}`),
                          })}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, md: 3 },
                    key: 'totalWeight',
                    formatValue: () => (material.unitWeight ?? 0) * (material.initialQty ?? 0),
                    props: {
                      label: t('materials:attributes.weight.total'),
                      placeholder: t('materials:attributes.weight.total'),
                      readOnly: true,
                      endAdornment: material?.unit ? (
                        <EndAdornmentLabel>
                          {t('materials:attributes.weight.kg', { value: '' })}
                        </EndAdornmentLabel>
                      ) : undefined,
                    },
                  },
                ],
              }),
              {
                type: ItemType.group,
                key: 'carbon',
                props: {
                  title: t('materials:attributes.carbon.title'),
                },
                items: [
                  {
                    type: ItemType.number,
                    grid: { xs: 12, sm: 4, md: 3 },
                    rules: [YupUtils.FieldValidationType.positive],
                    key: 'carbonTotal',
                    props: {
                      label: t('materials:attributes.carbon.carbonTotal'),
                      placeholder: t('materials:attributes.carbon.carbonTotalPlaceholder'),
                      endAdornment: <EndAdornmentLabel>kgCO2/UF</EndAdornmentLabel>,
                    },
                  },
                  {
                    type: ItemType.text,
                    grid: { xs: 12, sm: 8, md: 9 },
                    key: 'tracksOfReuse',
                    props: {
                      label: t('materials:attributes.carbon.tracksOfReuse'),
                      placeholder: t('materials:attributes.carbon.tracksOfReuse'),
                    },
                  },
                ],
              },
            ],
          },
          (material: ManageMaterial) => ({
            type: ItemType.group,
            key: 'quantitiesGroup',
            hideItem: isNeedRef.current,
            rules: [
              (material: ManageMaterial) =>
                material.initialQty < 0 || material.currentQty < 0
                  ? t('errors:quantityNegative')
                  : '',
            ],
            props: {
              accordion: true,
              endTitle: `${t(
                'materials:attributes.quantities.current',
              )} : ${StringUtils.formatNumber(material.currentQty ?? 0)} ${
                material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''
              }`,
              title: t('materials:attributes.quantities.title'),
            },
            items: [
              {
                type: ItemType.custom,
                key: 'quantities',
                custom: (
                  <MaterialTableQuantities
                    quantities={material.quantities}
                    unit={material.unit}
                    setValue={setValue}
                  />
                ),
              },
              {
                type: ItemType.group,
                key: 'totalGroup',
                props: {
                  title: t('materials:attributes.quantities.globalSubTitle'),
                },
                items: [
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 3 },
                    key: 'initialQty',
                    props: {
                      readOnly: true,
                      endAdornment: (
                        <EndAdornmentLabel>
                          {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                        </EndAdornmentLabel>
                      ),
                      label: t('materials:attributes.quantities.initial'),
                      placeholder: t('materials:attributes.quantities.initial'),
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 3 },
                    key: 'currentQty',
                    props: {
                      readOnly: true,
                      endAdornment: (
                        <EndAdornmentLabel>
                          {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                        </EndAdornmentLabel>
                      ),
                      label: t('materials:attributes.quantities.current'),
                      placeholder: t('materials:attributes.quantities.current'),
                    },
                  },
                  {
                    type: ItemType.number,
                    grid: { xs: 6, sm: 3 },
                    key: 'reservedQty',
                    hideItem: Constants.getIsLocal(),
                    props: {
                      readOnly: true,
                      endAdornment: (
                        <EndAdornmentLabel>
                          {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                        </EndAdornmentLabel>
                      ),
                      label: t('materials:attributes.quantities.reserved'),
                      placeholder: t('materials:attributes.quantities.reserved'),
                    },
                  },
                  {
                    type: ItemType.select,
                    grid: { xs: 6, sm: 3 },
                    key: 'quality',
                    props: {
                      readOnly: true,
                      label: t('materials:attributes.globalQuality'),
                      placeholder: t('global:inputs.selectPlaceholder'),
                      items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
                    },
                  },
                ] as FormItem[],
              },
            ] as FormItem[],
          }),
          {
            type: ItemType.group,
            key: 'sell',
            hideItem: isNeedRef.current,
            props: {
              accordion: true,
              title: t('materials:attributes.sell.title'),
            },
            items: [
              (material: ManageMaterial) => ({
                type: ItemType.number,
                grid: { xs: 12, sm: 6, md: 3 },
                key: 'sellByQuantityOf',
                required: true,
                rules: [YupUtils.FieldValidationType.positive],
                props: {
                  endAdornment: (
                    <EndAdornmentLabel>
                      {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                    </EndAdornmentLabel>
                  ),
                  label: t('materials:attributes.sell.sellByQuantityOf'),
                  placeholder: t('materials:attributes.sell.sellByQuantityOf'),
                  min: 0,
                },
              }),
              (material: ManageMaterial) => ({
                type: ItemType.number,
                grid: { xs: 12, sm: 6, md: 3 },
                key: 'minQuantity',
                rules: [
                  YupUtils.FieldValidationType.positive,
                  (value) =>
                    !value.sellByQuantityOf ||
                    !value.minQuantity ||
                    value.minQuantity % value.sellByQuantityOf === 0
                      ? ''
                      : t('errors:multipleOf', { value: value.sellByQuantityOf }),
                ],
                props: {
                  endAdornment: (
                    <EndAdornmentLabel>
                      {material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                    </EndAdornmentLabel>
                  ),
                  label: t('materials:attributes.sell.minQuantity'),
                  placeholder: t('materials:attributes.sell.minQuantity'),
                  step: material.sellByQuantityOf ?? 1,
                  min: 0,
                },
              }),
              {
                type: ItemType.select,
                grid: { xs: 12, sm: 6, md: 3 },
                key: 'termsOfSale',
                props: {
                  label: t('materials:attributes.sell.termsOfSale'),
                  placeholder: t('global:inputs.selectPlaceholder'),
                  items: createOptionsFromEnum(TermsOfSale, 'materials:termsOfSale'),
                },
              },
              (material: ManageMaterial) => ({
                type: ItemType.number,
                grid: { xs: 12, sm: 6, md: 3 },
                hideItem: material.termsOfSale !== TermsOfSale.sale,
                key: 'price',
                rules: [YupUtils.FieldValidationType.positive],
                required: material.termsOfSale === TermsOfSale.sale,
                props: {
                  label: t('materials:attributes.sell.price'),
                  placeholder: t('materials:attributes.sell.price'),
                  endAdornment: (
                    <EndAdornmentLabel>
                      {t(
                        `global:currency.${
                          material?.currency ||
                          (sessionService.getCountryParam('currency') as Currency)
                        }`,
                      )}
                      /{material.unit ? t(`materials:unitSymbol.${material.unit}`) : ''}
                    </EndAdornmentLabel>
                  ),
                },
              }),
            ] as FormItem[],
          },
          {
            type: ItemType.group,
            key: 'retrieval',
            hideItem: isNeedRef.current,
            props: {
              accordion: true,
              title: t('materials:attributes.retrieval.title'),
            },
            items: [
              {
                type: ItemType.select,
                grid: { xs: 12, sm: 6 },
                key: 'state',
                props: {
                  label: t('materials:attributes.state'),
                  placeholder: t('global:inputs.selectPlaceholder'),
                  items: createOptionsFromEnum(MaterialState, 'materials:state'),
                },
              },
              {
                type: ItemType.checkbox,
                grid: { xs: 12, md: 6 },
                key: 'retrieval.fromDefault',
                formatValue: (fromDefault) => fromDefault !== false,
                props: {
                  label: t('materials:attributes.retrieval.fromDefault'),
                },
              },
              (material: ManageMaterial) => ({
                type: ItemType.date,
                grid: { xs: 12, sm: 6 },
                hideItem: material?.retrieval?.fromDefault !== false,
                key: 'retrieval.startDate',
                rules: [
                  () =>
                    material.retrieval &&
                    material.retrieval.startDate &&
                    material.retrieval.endDate &&
                    new Date(material.retrieval.endDate).getTime() <
                      new Date(material.retrieval.startDate).getTime()
                      ? t('errors:startAfterEnd')
                      : undefined,
                ],
                props: {
                  label: t('materials:attributes.retrieval.startDate'),
                },
              }),
              (material: ManageMaterial) => ({
                type: ItemType.date,
                grid: { xs: 12, sm: 6 },
                key: 'retrieval.endDate',
                rules: [
                  () =>
                    material.retrieval &&
                    material.retrieval.startDate &&
                    material.retrieval.endDate &&
                    new Date(material.retrieval.endDate).getTime() <
                      new Date(material.retrieval.startDate).getTime()
                      ? t('errors:startAfterEnd')
                      : undefined,
                ],
                props: {
                  label: t('materials:attributes.retrieval.endDate'),
                },
                hideItem: material?.retrieval?.fromDefault !== false,
              }),
              (material: ManageMaterial) => ({
                type: ItemType.select,
                grid: { xs: 12, sm: 6 },
                key: 'retrieval.retrievalModality',
                props: {
                  label: t('materials:attributes.retrieval.modality'),
                  placeholder: t('global:inputs.selectPlaceholder'),
                  items: createOptionsFromEnum(RetrievalModality, 'global:retrievalModality'),
                },
                hideItem: material?.retrieval?.fromDefault !== false,
              }),
              {
                type: ItemType.text,
                key: 'conditioning',
                props: {
                  multiline: true,
                  minRows: 2,
                  label: t('materials:attributes.conditioning'),
                  placeholder: t('materials:attributes.conditioning'),
                },
              },
            ] as FormItem[],
          },
        ],
      },
      {
        title: t('materials:components.modalForm.documents'),
        items: [
          (material: ManageMaterial) => ({
            key: 'filesFile',
            type: ItemType.documents,
            grid: { xs: 12, sm: 6 },
            props: {
              type: 'file',
              documents: material.files,
              label: t('materials:attributes.files'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  filesFile: (state.filesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  filesFile: (state.filesFile ?? []).filter((_, fIndex) => fIndex !== index),
                }))
              },
              onActionDocument: (documentId: string) =>
                setValue((state) => ({
                  ...state,
                  files: (state.files ?? []).filter((file) => file._id !== documentId),
                })),
              maxFileSize: 2.5,
            },
          }),
          (material: ManageMaterial) => ({
            key: 'imageFilesFile',
            type: ItemType.documents,
            grid: { xs: 12, sm: 6 },
            props: {
              type: 'image',
              documents: material.imageFiles,
              label: t('materials:attributes.imageFiles'),
              onSelectFiles: (newDocuments: File[]) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).concat(newDocuments),
                }))
              },
              actionIcon: <DeleteIcon />,
              onActionFile: (index: number) => {
                setValue((state) => ({
                  ...state,
                  imageFilesFile: (state.imageFilesFile ?? []).filter(
                    (_, fIndex) => fIndex !== index,
                  ),
                }))
              },
              onActionDocument: (documentId: string) =>
                setValue((state) => ({
                  ...state,
                  imageFiles: (state.imageFiles ?? []).filter((file) => file._id !== documentId),
                })),
              maxFileSize: 2.5,
            },
          }),
        ],
      },
    ],
    [
      t,
      value.primaryCategory,
      value.secondaryCategory,
      value.tertiaryCategory,
      materialModelOptions,
      goTo,
    ],
  )

  return (
    <ModalForm
      {...formProps}
      onDelete={material._id && onDelete ? () => onDelete(material._id as string) : undefined}
      onTransfer={material._id  && onTransfer ? () => onTransfer(material._id as string) : undefined}
      onDuplicate={material._id && onDuplicate ? () => onDuplicate(material._id as string) : undefined}
      value={value}
      setValue={handleSetValue}
      tabs={tabs}
      maxWidth="lg"
    />
  )
}
export default ModalFormMaterial
