import { useTranslation } from 'react-i18next'
import { AdminHeaderTitle } from '../components/layout/AdminHeader.layout'
import LoaderOverlay from '../components/layout/LoaderOverlay.layout'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {alpha, Box, Button, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme} from '@mui/material'
import ArrayItem from '../components/common/item/Array.item'
import { Filter, FilterItem, ItemType, SelectOption } from '../models/props.models'
import { organizationsService } from '../store/organizations'

import { useLocation, useParams } from 'react-router-dom'
import { sessionService } from '../store/session'
import Stat from '../components/common/Stat.common'
import {
  BusinessCenter as BusinessCenterIcon,
  Delete as DeleteIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  EnergySavingsLeaf as EnergySavingsLeafIcon,
  Euro as EuroIcon,
  Person as PersonIcon,
  ShoppingBasket as ShoppingBasketIcon,
} from '@mui/icons-material'
import { reportsService } from '../store/reports'
import useOwnSnackbar from '../hooks/useSnackbar.hooks'
import { Reports } from '../models/reports.model'
import { TermsOfSale } from '../models/materials.models'
import { catalogsService } from '../store/catalogs'
import { createOptionsFromEnum } from '../utils/i18n.utils'
import { BuildingType } from '../models/catalogs.models'
import { Category } from '../models/categories.models'
import constants from '../constants'
import Constants from '../constants'
import { createOptionsFromYearStart } from '../utils/dayjs.utils'
import InputMultiSelectCategory from '../components/category/Input.MultiSelectCategory'
import { Mode, Route } from '../models/commons.models'
import useRoute from '../hooks/useRoute.hooks'
import ModalHoursDedicatedToReuse from '../components/reporting/ModalHoursDedicated.reporting'
import Actions from '../components/common/Actions.common'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { savedFiltersService } from '../store/savedFilters'
import { SavedFiltersTypeEnum, SavedFilterValue, SavedFilterValueItems} from '../models/savedFilters.models'
import SelectInput from '../components/common/input/Select.input'
import { PDFUtils, StringUtils } from '../utils/commons.utils'
import Modal from '../components/layout/Modal.layout'
import ModalSaveFilter from '../components/reporting/ModalSaveFilter.reporting';

const ReportingPage = () => {
  const {t} = useTranslation()
  const show = useOwnSnackbar()
  const theme = useTheme()
  const downLg = useMediaQuery(theme.breakpoints.down('lg'))
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  const location = useLocation()
  const locationRef = useRef(location.pathname)
  useEffect(() => {
    locationRef.current = location.pathname
  }, [location])
  const { isAtRoute } = useRoute()
  const { catalogId } = useParams()

  const reportsContainerRef = useRef<HTMLDivElement>(null)

  const [loadingReports, setLoadingReports] = useState(false)
  const [savedFilters, setSavedFilters] = useState<SavedFilterValue[]>([])
  const [filters, setFilters] = useState<Record<string, any>>(sessionService.getFilter(locationRef.current))
  const [reports, setReports] = useState<Reports>()
  const [selectedCategories, setSelectedCategories] = useState<
    { primaryCategory?: Category; secondaryCategory?: Category; tertiaryCategory?: Category }[]
  >([])
  const [openModalHoursDedicatedToReuse, setOpenModalHoursDedicatedToReuse] = useState(false)
  const [openSaveFilter, setOpenSaveFilter] = useState(false)
  const [openModalSavedFilterSuccess, setOpenModalSavedFilterSuccess] = useState(false)

  const isAtWorkspaceCatalogReportingRoute = useMemo(() => isAtRoute(Route.workspaceCatalogReporting), [isAtRoute])
  const isAtAdminReportingRoute = useMemo(() => Constants.mode === Mode.admin, [])

  const parseFilter = useCallback(
    () => ({
      owned: !isAtAdminReportingRoute,
      catalogOwners: filters.organizations?.map((x: SelectOption) => x.value),
      catalogs: isAtWorkspaceCatalogReportingRoute ? [catalogId] : filters.catalogs?.map((x: SelectOption) => x.value),
      buildingTypes: filters.buildingTypes,
      tertiaryCategories: filters.tertiaryCategories?.map((x: any) => x.tertiaryCategory._id) || [],
      years: filters.years,
    }),
    [catalogId, filters, isAtAdminReportingRoute, isAtWorkspaceCatalogReportingRoute],
  )

  const parseSavableFiltersItems = useCallback(() => {
    const savableFilterItems: SavedFilterValueItems[] = Object.entries(filters)
      .map(([key, value]) => ({
        field: key,
        values: value.map((v: any) => typeof v === 'object'
          ? {
            ...v,
            data: undefined,
            primaryCategory: v.primaryCategory
              ? { name: v.primaryCategory.name, _id: v.primaryCategory._id }
              : undefined,
            secondaryCategory: v.secondaryCategory
              ? { name: v.secondaryCategory.name, _id: v.secondaryCategory._id }
              : undefined,
            tertiaryCategory: v.tertiaryCategory
              ? { name: v.tertiaryCategory.name, _id: v.tertiaryCategory._id }
              : undefined,
          } : v
        ),
      }))
      .filter((item) => item.values.length)

    return savableFilterItems
  }, [filters])

  const setFiltersFromSavedFilter = useCallback((savedFilterValueItems: SavedFilterValue) => {
    const newFilters: any = savedFilterValueItems.items.reduce((acc, item) => {
      return { ...acc, [item.field]: item.values }
    }, {})

    setSelectedCategories(newFilters.tertiaryCategories ?? [])

    setFilters(newFilters)
  }, [])

  const hasAtLeastOneFilterSet = useMemo(() => !!Object.values(filters).filter((x) => !!x?.length)?.length, [filters])

  const exportXlsx = useCallback(async () => {
    setLoadingReports(true)
    try {
      await reportsService.getXlsx(parseFilter())
    } catch (err: any) {
      show(t('errors:global'), 'error')
    }
    setLoadingReports(false)
  }, [parseFilter, show, t])

  const exportPdf = useCallback(async () => {
    setLoadingReports(true)
    try {
      if (!reportsContainerRef.current) throw new Error('No container to export')
      const response = await PDFUtils.captureContainerAsImage(reportsContainerRef.current)
      if (!response) throw new Error('No screenshot to export')
      const { imgData, ratio } = response
      PDFUtils.convertToPdf(imgData, ratio, 'reports')
    } catch (err: any) {
      show(t('errors:global'))
    }
    setLoadingReports(false)
  }, [show, t])

  const updateHoursDedicatedToReuse = useCallback(
    async (newValue: { hoursDedicatedToReuse: number }) => {
      if (!catalogId) {
        return
      }
      try {
        await catalogsService.updateCatalog(catalogId, { ...newValue })
      } catch (err: any) {
        show(err)
      }
    },
    [catalogId, show]
  )

  const filter = useMemo<Filter>(
    () => ({
      items: [
        ...((Constants.mode === Mode.admin && !isAtWorkspaceCatalogReportingRoute
          ? [
              {
                type: ItemType.asyncMultiSelect,
                key: 'organizations',
                grid: { lg: 2, sm: 6, xs: 12 },
                props: {
                  placeholder: t('reports:components.list.organizationFilter'),
                  minimumSearchLength: 3,
                  getOptions: (search?: string) => organizationsService.getOrganizationOptions(search),
                },
              },
            ]
          : []) as FilterItem[]),
        ...((!isAtWorkspaceCatalogReportingRoute
          ? [
            {
              type: ItemType.asyncMultiSelect,
              key: 'catalogs',
              grid: {lg: 2, sm: 6, xs: 12},
              props: {
                placeholder: t('reports:components.list.catalogFilter'),
                minimumSearchLength: 3,
                getOptions: (search?: string) => catalogsService.getCatalogOptions({search}),
              },
            },
          ]
          : []) as FilterItem[]),
        {
          type: ItemType.custom,
          key: 'tertiaryCategories',
          grid: { lg: 2, sm: 6, xs: 12 },
          position: 'modal',
          isFilled: !!selectedCategories.length,
          custom: (
            <InputMultiSelectCategory
              placeholder={t('reports:components.list.categoryFilter')}
              selectedCategories={selectedCategories}
              onChange={(selectedValue: any) => {
                setFilters((currentFilters: any) => {
                  const isClearAction = selectedValue === 'clear'
                  const currentCategories = currentFilters.tertiaryCategories || []
                  let updatedCategories = currentCategories

                  if (!isClearAction) {
                    const isExistingCategory = currentCategories.some(
                      (category: any) => category.tertiaryCategory._id === selectedValue.tertiaryCategory._id
                    )
                    updatedCategories = isExistingCategory
                      ? currentCategories.filter((category: any) => category.tertiaryCategory._id !== selectedValue.tertiaryCategory._id)
                      : [...currentCategories, selectedValue]
                  }

                  return {
                    ...currentFilters,
                    tertiaryCategories: isClearAction ? [] : updatedCategories,
                  }
                })

                setSelectedCategories((currentCategories: any) => {
                  const isClearAction = selectedValue === 'clear'
                  let updatedCategories = currentCategories || []

                  if (!isClearAction) {
                    const isExistingCategory = currentCategories?.some(
                      (category: any) => category.tertiaryCategory._id === selectedValue.tertiaryCategory._id
                    )
                    updatedCategories = isExistingCategory
                      ? currentCategories.filter((category: any) => category.tertiaryCategory._id !== selectedValue.tertiaryCategory._id)
                      : [...currentCategories, selectedValue]
                  }

                  return isClearAction ? [] : updatedCategories
                })
              }}
            />
          ),
        },
        ...((Constants.mode === Mode.admin && !isAtWorkspaceCatalogReportingRoute
          ? [
            {
              type: ItemType.multiSelect,
              key: 'buildingTypes',
              grid: { lg: 3, sm: 6, xs: 12 },
              props: {
                multiple: true,
                size: 'normal',
                placeholder: t('reports:components.list.buildingTypeFilter'),
                items: createOptionsFromEnum(BuildingType, 'catalogs:buildingType'),
              },
            } as FilterItem,
          ]
          : []) as FilterItem[]),
        {
          type: ItemType.multiSelect,
          key: 'years',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            multiple: true,
            size: 'normal',
            placeholder: t('reports:components.list.dateFilter'),
            items: createOptionsFromYearStart(constants.startYearReportFilter),
          },
        },
      ],
    }),
    [isAtWorkspaceCatalogReportingRoute, selectedCategories, t],
  )

  const getReports = useCallback(async () => {
    setLoadingReports(true)
    try {
      const data = await reportsService.getReports(parseFilter())
      setReports(data)
    } catch (err: any) {
      show(err)
    }
    setLoadingReports(false)
  }, [parseFilter, show])

  const getSavedFilters = useCallback(async () => {
    try {
      let data = await savedFiltersService.getSavedFilters(
        isAtAdminReportingRoute ? SavedFiltersTypeEnum.ADMIN_REPORTS : SavedFiltersTypeEnum.OWN_CATALOGS_REPORTS
      )
      if (!data) return

      if (isAtWorkspaceCatalogReportingRoute) {
        data = data.filter(
          (x) =>
            !x.items.some((y) => y.field === 'catalogs') ||
            x.items.some((y) => y.field === 'catalogs' && y.values.some((z: any) => z.value === catalogId))
        )
      }

      setSavedFilters(data)
    } catch (err: any) {
      show(err)
    }
  }, [catalogId, isAtAdminReportingRoute, isAtWorkspaceCatalogReportingRoute, show])

  useEffect(() => {
    getSavedFilters()
    getReports()
  }, [getReports, getSavedFilters])

  const saveFilters = useCallback(async (newValue: { name: string }) => {
    try {
      let newSavedFilters = await savedFiltersService.saveFilter(
        isAtAdminReportingRoute ? SavedFiltersTypeEnum.ADMIN_REPORTS : SavedFiltersTypeEnum.OWN_CATALOGS_REPORTS,
        {
          name: newValue.name,
          items: parseSavableFiltersItems(),
        },
      )

      if (isAtWorkspaceCatalogReportingRoute) {
        newSavedFilters = newSavedFilters.filter(
          (x) =>
            !x.items.some((y) => y.field === 'catalogs') ||
            x.items.some((y) => y.field === 'catalogs' && y.values.some((z: any) => z.value === catalogId))
        )
      }

      setSavedFilters(newSavedFilters)
      setOpenModalSavedFilterSuccess(true)
    } catch (err: any) {
      show(err)
    }
  }, [catalogId, isAtAdminReportingRoute, isAtWorkspaceCatalogReportingRoute, parseSavableFiltersItems, show, t])

  const chartData = useMemo(
    () => ({
      environmentBalance: reports ? [{ name: '', value: reports.tonnage.total }] : [], //TODO later multiple values
      economicBalance: [],
      totalOrders: reports
        ? reports.orders.perStatus.map((x) => ({ name: t(`orders:status.${x.status}`), value: x.count }))
        : [],
      catalogsPerType: reports
        ? reports.catalogs.perType.map((x) => ({ name: t(`catalogs:type.${x.type}`), value: x.count }))
        : [],
      clientsPerLegalForm: reports
        ? reports.clients.perLegalForm.map((x) => ({ name: t(`organizations:legalForms.${x.legalForm}`), value: x.count }))
        : [],
      reusedMaterialsByCategory: reports
        ? reports.products.perPrimaryCategory.map((x) => ({
            name: x.primaryCategory.name,
            value: x.count,
            metaData: {
              secondaryCategoryDetails: reports.products.perSecondaryCategory
                .filter((secondaryCat) => secondaryCat.secondaryCategory.parent === x.primaryCategory._id)
                .map((y) => ({
                  name: y.secondaryCategory.name,
                  value: y.count,
                })),
            },
          }))
        : [],
    }),
    [reports, t],
  )

  const totalSaleCessionsMade = useMemo(
    () => reports?.products.perTermsOfSale.reduce((acc, x) => acc + (x.termsOfSale === TermsOfSale.sale ? x.count : 0), 0) || 0,
    [reports]
  )

  const totalGiftCessionsMade = useMemo(
    () => reports?.products.perTermsOfSale.reduce((acc, x) => acc + (x.termsOfSale === TermsOfSale.donation ? x.count : 0), 0) || 0,
    [reports]
  )

  const totalCessionsMade = useMemo(() => reports?.products.perTermsOfSale.reduce((acc, x) => acc + x.count, 0) || 0, [reports])

  return (
    <>
      {isAtAdminReportingRoute && !isAtWorkspaceCatalogReportingRoute && (
        <AdminHeaderTitle title={t('global:routes.reporting')} />
      )}
      {loadingReports && <LoaderOverlay />}
      <Box padding={`15px ${onlyXs ? '10px' : '15px'}`} width="100%" display="flex" position="relative" flexDirection="column" flexGrow="1">
        <Stack width="100%" mb="24px" direction={downLg ? 'column' : 'row'} justifyContent="space-between">
          <Grid container spacing={3}>
            <ArrayItem items={filter.items} value={filters} setValue={setFilters} />
            <Grid item lg={isAtAdminReportingRoute ? 1 : isAtWorkspaceCatalogReportingRoute ? 8 : 6} sm={6} xs={12}>
              <Stack width="100%" direction={downLg ? undefined : 'row'} justifyContent={downLg ? undefined : 'flex-end'}>
                <Actions
                  customButton={
                    <Button fullWidth variant="contained">
                      {t('reports:actions.export')}
                    </Button>
                  }
                  items={[
                    {
                      label: t('reports:actions.exportXlsx'),
                      icon: <DescriptionOutlinedIcon sx={{ fontSize: '12px' }} />,
                      onClick: exportXlsx,
                    },
                    {
                      label: t('reports:actions.exportPdf'),
                      icon: <DescriptionOutlinedIcon sx={{ fontSize: '12px' }} />,
                      onClick: exportPdf,
                    },
                  ]}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        {!!savedFilters.length && (
          <Stack width="100%" mb="24px">
            <Box width={downLg ? '100%' : '250px'}>
              <SelectInput
                items={savedFilters.map((x) => ({ label: x.name, value: x }))}
                placeholder={t('reports:components.list.savedFilters')}
                onChange={(value) => {
                  setFiltersFromSavedFilter(value as SavedFilterValue)
                }}
                renderCustomMenuItem={(item) => (
                  <Stack width="100%" spacing="10px" py="10px">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" spacing="5px">
                      <Typography fontSize="12px" fontWeight="600" lineHeight="1.25">{item.label}</Typography>
                      <IconButton
                        size="small"
                        onClick={async (e) => {
                          e.stopPropagation()
                          let newSavedFilters = await savedFiltersService.deleteFilter(
                            isAtAdminReportingRoute ? SavedFiltersTypeEnum.ADMIN_REPORTS : SavedFiltersTypeEnum.OWN_CATALOGS_REPORTS,
                            item.label
                          )

                          if (isAtWorkspaceCatalogReportingRoute) {
                            newSavedFilters = newSavedFilters.filter(
                              (x) =>
                                !x.items.some((y) => y.field === 'catalogs') ||
                                x.items.some((y) => y.field === 'catalogs' && y.values.some((z: any) => z.value === catalogId))
                            )
                          }

                          setSavedFilters(newSavedFilters)
                        }}>
                        <DeleteOutlineOutlinedIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Stack>
                    {item.value.items.map((i: any) => (
                      <>
                        {['organizations', 'catalogs'].includes(i.field) && (
                          <Typography fontSize="12px" fontWeight="500" lineHeight="1.25">
                            {i.values.map((v: any) => 'label' in v ? v.label : v).join(', ')}
                          </Typography>
                        )}
                        {['buildingTypes'].includes(i.field) && (
                          <Typography fontSize="12px" fontWeight="500" lineHeight="1.25">
                            {i.values.map((v: BuildingType) => t(`catalogs:buildingType.${v}`)).join(', ')}
                          </Typography>
                        )}
                        {['tertiaryCategories'].includes(i.field) && (
                          <Typography fontSize="12px" fontWeight="500" lineHeight="1.25">
                            {i.values.map((v: any) => v.tertiaryCategory.name).join(', ')}
                          </Typography>
                        )}
                        {['years'].includes(i.field) && (
                          <Typography fontSize="12px" fontWeight="500" lineHeight="1.25">
                            {i.values.map((v: any) => v).join(' - ')}
                          </Typography>
                        )}
                      </>
                    ))}
                  </Stack>
                )}
              />
            </Box>
          </Stack>
        )}
        <Grid container spacing="58px" ref={reportsContainerRef}>
          <Grid item xs={12} lg={6}>
            <Stack>
              <Typography fontSize="16px" fontWeight={600} mb="16px">
                {t('reports:attributes.environmentBalance')}
              </Typography>
              <Grid container spacing="25px" justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.reusedMaterials')}
                    titleIcon={<DeleteIcon />}
                    number={reports?.tonnage.total || 0}
                    numberSubtitle={t('reports:attributes.tonneUnitFull')}
                    hideLegend={chartData.environmentBalance.length <= 1}
                    chartType="half-pie"
                    chartData={chartData.environmentBalance}
                    unit={t('reports:attributes.tonneUnit')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stat
                    title={t('reports:attributes.carbonEconomyViaReuse')}
                    number={reports?.carbonEconomy || 0}
                    numberSubtitle={t('reports:attributes.co2Tones')}
                    moreInfoText={t('reports:attributes.equialentCarTrip', {
                      arNb: !!reports?.carbonEconomy ? (reports.carbonEconomy * 1000) / 107.256 : 0,
                    })}
                    titleIcon={<EnergySavingsLeafIcon />}
                    moreInfoIcon={<EnergySavingsLeafIcon />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stat
                    title={t('reports:attributes.reusedMaterialsByCategory')}
                    chartType="bar"
                    titleIcon={<img src={constants.resources.materialIcon} alt="material" />}
                    chartData={chartData.reusedMaterialsByCategory}
                    barChartYAxisWidth={200}
                    toolTipContent={(chartData) => {
                      return (
                        <Stack spacing="16px" width="210px">
                          <Typography fontSize="12px" fontWeight={600} width="100%" textAlign="center">
                            {chartData.name}
                          </Typography>
                          <Stack>
                            {chartData.metaData?.secondaryCategoryDetails
                              ? chartData.metaData.secondaryCategoryDetails.map((x: any, index: number) => (
                                  <Stack key={x.name}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                      <Typography fontSize="12px" fontWeight={500}>
                                        {x.name}
                                      </Typography>
                                      <Typography fontSize="12px" fontWeight={600}>
                                        {x.value}
                                      </Typography>
                                    </Stack>
                                    {index !== chartData.metaData.secondaryCategoryDetails.length - 1 && (
                                      <Box my="7px" width="100%" height="0.5px" bgcolor={alpha('#ffff', 0.13)} />
                                    )}
                                  </Stack>
                                ))
                              : null}
                          </Stack>
                        </Stack>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack>
              <Typography fontSize="16px" fontWeight={600} mb="16px">
                {t('reports:attributes.economicBalance')}
              </Typography>
              <Grid container spacing="25px" justifyContent="flex-start">
                {isAtAdminReportingRoute && (
                  <Grid item xs={12} md={6}>
                    <Stat
                      title={t('reports:attributes.totalOrders')}
                      number={reports?.orders.total || 0}
                      numberSubtitle={t('reports:attributes.orders')}
                      chartType="full-pie"
                      chartData={chartData.totalOrders}
                      titleIcon={<ShoppingBasketIcon />}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Stack spacing="25px">
                    <Stat
                      title={t('reports:attributes.totalReuseSale')}
                      number={reports?.totalPrice || 0}
                      numberSubtitle={t('reports:attributes.currency')}
                      titleIcon={<EuroIcon />}
                    />
                    <Box/>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stat
                        title={t('reports:attributes.totalSaleCessionsMade')}
                        number={totalSaleCessionsMade}
                        numberSubtitle={t('reports:attributes.percentageCessions', {
                          percentage:
                            !totalSaleCessionsMade || !totalCessionsMade ? 0 : ((totalSaleCessionsMade / totalCessionsMade) * 100).toFixed(1),
                        })}
                        titleIcon={<img src={constants.resources.handIcon} alt="cession" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stat
                        title={t('reports:attributes.totalGiftCessionsMade')}
                        number={totalGiftCessionsMade}
                        numberSubtitle={t('reports:attributes.percentageCessions', {
                          percentage:
                            !totalGiftCessionsMade || !totalCessionsMade ? 0 : ((totalGiftCessionsMade / totalCessionsMade) * 100).toFixed(1),
                        })}
                        titleIcon={<img src={constants.resources.handIcon} alt="cession" />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {isAtAdminReportingRoute && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Grid item xs={12}>
                      <Stat
                        title={t('reports:attributes.totalProjects')}
                        number={reports?.catalogs.total || 0}
                        numberSubtitle={t('reports:attributes.projects')}
                        chartType="full-pie"
                        chartData={chartData.catalogsPerType}
                        titleIcon={<img src={constants.resources.projectsIcon} alt="projects" />}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{mt: '30px'}}>
            <Stack>
              <Typography fontSize="16px" fontWeight={600} mb="16px">
                {t('reports:attributes.balanceBuyers')}
              </Typography>
              <Grid container spacing="25px">
                <Grid item xs={12} lg={6}>
                  <Grid container spacing="25px" justifyContent="flex-start">
                    <Grid item xs={12} md={6}>
                      <Stat
                        title={t('reports:attributes.totalBuyers')}
                        number={reports?.clients.total || 0}
                        numberSubtitle={t('reports:attributes.buyers')}
                        titleIcon={<PersonIcon />}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stat
                        title={t('reports:attributes.totalHoursDedicatedToReuse')}
                        number={reports?.hoursDedicatedToReuse || 0}
                        numberSubtitle={t('reports:attributes.hours')}
                        titleIcon={<BusinessCenterIcon />}
                        setOpenModalConfirm={isAtWorkspaceCatalogReportingRoute ? setOpenModalHoursDedicatedToReuse : undefined}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container>
                    <Stat
                      title={t('reports:attributes.buyersByLegalStructure')}
                      chartType="bar"
                      titleIcon={<PersonIcon />}
                      chartData={chartData.clientsPerLegalForm}
                      barChartYAxisWidth={200}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        {hasAtLeastOneFilterSet && (
          <Stack width="100%" position="fixed" bottom="30px" alignItems="center">
            <Button variant="contained" onClick={() => setOpenSaveFilter(true)}>
              {t('reports:actions.saveFilters')}
            </Button>
          </Stack>
        )}
        {openSaveFilter && (
          <ModalSaveFilter
            onSubmit={saveFilters}
            onClose={() => setOpenSaveFilter(false)}
          />
        )}
        {openModalHoursDedicatedToReuse && (
          <ModalHoursDedicatedToReuse
            onSubmit={updateHoursDedicatedToReuse}
            onSuccess={async () => {
              getReports()
              show(t('reports:messages.hoursDedicatedToReuseSuccess'), 'success')
            }}
            onClose={() => setOpenModalHoursDedicatedToReuse(false)}
          />
        )}
        {openModalSavedFilterSuccess && (
          <Modal
            title={t('reports:messages.savedFilterSuccess')}
            onClose={() => setOpenModalSavedFilterSuccess(false)}
            description={t('reports:messages.savedFilterSuccessDescription')}
          />
        )}
      </Box>
    </>
  )
}

export default ReportingPage
