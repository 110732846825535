import React from 'react'
import { alpha, Box, DialogActions, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Modal from '../layout/Modal.layout'
import Stack from '../common/Stack.common'
import ImageWithBackground from '../common/ImageWithBackground.common'
import Documents from '../common/input/Documents.input'

import Constants from '../../constants'
import { Material, MaterialType, TermsOfSale } from '../../models/materials.models'
import { Route } from '../../models/commons.models'
import { FileUtils } from '../../utils/files.utils'
import LinkButton from '../common/button/Link.button'
import useRoute from '../../hooks/useRoute.hooks'
import { StringUtils } from '../../utils/commons.utils'
import AddToCart from './AddToCart.material'

const MaterialPicture = styled(Box)({
  height: '200px',
  minHeight: '200px',
  position: 'relative',
  width: '100%',
})

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const PriceLabel = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: 'white',
  borderRadius: '4px',
  backgroundColor: 'black',
  padding: '7px 9px',
})
const CatalogLabel = styled(OneLineTypography)({
  fontSize: '0.85rem',
  fontWeight: 300,
  marginTop: '8px',
})
const RefLabel = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 300,
  marginTop: '4px',
})
const CategoriesLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: 1.25,
  marginTop: '2px',
}))
const StatusLabel = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 400,
  marginTop: '9px',
})

const Block = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 500,
  width: '100%',
  padding: '12px 11px',
  marginTop: '14px',
})
const AvailableBlock = styled(Block)(({ theme }) => ({
  color: theme.palette.availableQuantity,
  backgroundColor: alpha(theme.palette.availableQuantity, 0.1),
}))
const DesiredBlock = styled(Block)(({ theme }) => ({
  color: theme.palette.desiredQuantity,
  backgroundColor: alpha(theme.palette.desiredQuantity, 0.1),
}))
const GreyBlock = styled(Block)(({ theme }) => ({
  color: theme.palette.blockLabel,
  backgroundColor: alpha(theme.palette.blockLabel, 0.1),
}))

const RowsTitle = styled(OneLineTypography)({
  fontSize: '0.75rem',
  fontWeight: 500,
  marginTop: '30px',
})
const RowDetail = styled(Stack)({
  width: '100%',
  padding: '14px 0',
  '&:not(:first-of-type)': {
    borderBottom: 'solid 1px #DFDFDF',
  },
})
const RowTextLeft = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
}))
const RowTextRight = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  textAlign: 'right',
})
const RowTextSeveralLines = styled(Typography)({ fontSize: '0.875rem', fontWeight: 400 })
const Observations = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
}))

interface MaterialDetailsModalProps {
  material: Material
  isPublic?: boolean
  isCatalogPage?: boolean
  onClose: () => void
  addToCart?: (quantity: number) => void
}

const MaterialDetailsModal: React.FC<MaterialDetailsModalProps> = ({
  material,
  isPublic,
  isCatalogPage,
  onClose,
  addToCart,
}): JSX.Element => {
  const { t } = useTranslation()
  const { matchPublic, goTo } = useRoute()

  if (!material) return <></>

  const filteredMaterial = material.technicalDetails?.filter((tech: any) => !!tech.value)

  return (
    <Modal whiteClose noContentPadding maxWidth="sm" onClose={onClose} hideAction>
      <MaterialPicture onClick={() => FileUtils.openFile(material.mainImageFile)}>
        <ImageWithBackground
          placeholder={Constants.resources.materialPlaceHolder}
          image={material.mainImageFile?.src || material.mainImageFile?.path}
        />
      </MaterialPicture>
      <Stack px="10px" mb="30px" overflow="hidden" width="100%">
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          px="20px"
          py="30px"
          spacing={6}
          sx={{
            maxHeight: `calc(90vh - 350px)`,
            overflow: 'hidden auto',
          }}
          className="scrollable">
          <Box display="flex" flexDirection="column" alignItems="stretch" width="100%">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <OneLineTypography variant="h2">{material.name}</OneLineTypography>
                <RefLabel>
                  {t('materials:details.reference', { reference: material.reference })}
                </RefLabel>

                <CategoriesLabel>
                  {t(`categories:name.${material.primaryCategory.name}` as any)} &#8594;{' '}
                  {t(`categories:name.${material.secondaryCategory.name}` as any)} &#8594;
                  {t(`categories:name.${material.tertiaryCategory.name}` as any)}
                </CategoriesLabel>
                {!isCatalogPage && (
                  <Box display="flex">
                    <CatalogLabel>{t('materials:attributes.catalog')}</CatalogLabel>
                    <LinkButton
                      onClick={() =>
                        goTo({
                          route: matchPublic ? Route.publicCatalog : Route.workspaceCatalog,
                          catalogId: material.catalog._id,
                        })
                      }>
                      {material.catalog.name}
                    </LinkButton>
                  </Box>
                )}
                {material.quality && (
                  <StatusLabel color="secondaryText">
                    {t(`materials:quality.${material.quality}`)}
                  </StatusLabel>
                )}
              </Box>
              {material.type === MaterialType.resource && (
                <PriceLabel>
                  {material.termsOfSale === TermsOfSale.sale &&
                    t('materials:details.price', {
                      price: material.price,
                      currency: t(`global:currency.${material.currency}`),
                      unit: t(`materials:unitSymbol.${material.unit}`),
                    })}
                  {material.termsOfSale === TermsOfSale.notDefined &&
                    t('materials:details.priceNotDefined')}
                  {material.termsOfSale === TermsOfSale.donation && t('materials:details.donation')}
                </PriceLabel>
              )}
            </Box>
            {material.type === MaterialType.resource && (
              <>
                <AvailableBlock>
                  {t('materials:details.quantityAvailable', {
                    count: material.currentQty || 0,
                    unit: t(`materials:unitSymbol.${material.unit}`),
                  })}
                </AvailableBlock>
                {!!material.unitWeight && (
                  <GreyBlock>
                    {t(`materials:details.weight${material.unitWeight > 999 ? 'T' : 'Kg'}`, {
                      weight:
                        material.unitWeight > 999
                          ? material.unitWeight / 1000
                          : material.unitWeight,
                      unit: t(`materials:unitSymbol.${material.unit}`),
                    })}
                  </GreyBlock>
                )}
              </>
            )}
            {material.type === MaterialType.need && (
              <>
                <DesiredBlock>
                  {t('materials:details.desiredQuantity', {
                    quantity: material.initialQty || 0,
                    unit: t(`materials:unitSymbol.${material.unit}`),
                  })}
                </DesiredBlock>
                {material?.retrieval?.endDate && (
                  <GreyBlock>
                    {`${t('materials:details.needsEndDate', {
                      date: new Date(material?.retrieval?.endDate || 'invalid'),
                    })}`}
                  </GreyBlock>
                )}
                {material.uniqueDeposit && (
                  <GreyBlock>{t('materials:details.uniqueDeposit')}</GreyBlock>
                )}
              </>
            )}
            {material.description && (
              <RowDetail alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
                <RowTextLeft>{t('materials:attributes.observations')}</RowTextLeft>
                <Observations>{material.description}</Observations>
              </RowDetail>
            )}
            {material.privateDescription && !isPublic && (
              <RowDetail alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
                <RowTextLeft>{t('materials:attributes.privateDescription')}</RowTextLeft>
                <Observations>{material.privateDescription}</Observations>
              </RowDetail>
            )}
            {material.type === MaterialType.resource && material.dimensions && (
              <>
                <RowsTitle>{t('materials:attributes.dimensions.title')}</RowsTitle>
                {!!material.dimensions?.width && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.width')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(material.dimensions.width)}
                      {material.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!material.dimensions?.height && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.height')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(material.dimensions.height)}
                      {material.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!material.dimensions?.length && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.length')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(material.dimensions.length)}
                      {material.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!material.dimensions?.thickness && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.thickness')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(material.dimensions.thickness)}
                      {material.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!material.dimensions?.depth && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.depth')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(material.dimensions.depth)}
                      {material.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
                {!!material.dimensions?.diameter && (
                  <RowDetail
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{t('materials:attributes.dimensions.diameter')}</RowTextLeft>
                    <RowTextRight>
                      {StringUtils.formatNumber(material.dimensions.diameter)}
                      {material.dimensions.unit
                        ? t(`materials:dimensionUnitSymbol.${material.dimensions.unit}`)
                        : ''}
                    </RowTextRight>
                  </RowDetail>
                )}
              </>
            )}
            {material.type === MaterialType.resource && !!filteredMaterial?.length && (
              <>
                <RowsTitle>{t('materials:attributes.technicalDetails.title')}</RowsTitle>
                {filteredMaterial?.map((td, i) => (
                  <RowDetail
                    key={i}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}>
                    <RowTextLeft>{td.name}</RowTextLeft>
                    <RowTextSeveralLines>{td.value}</RowTextSeveralLines>
                  </RowDetail>
                ))}
              </>
            )}
            {material.type === MaterialType.resource && (
              <>
                {(material.carbonTotal || material.tracksOfReuse) && (
                  <>
                    <RowsTitle>{t('materials:attributes.carbon.title')}</RowsTitle>
                    {material.carbonTotal && (
                      <RowDetail
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1.5}>
                        <RowTextLeft>{t('materials:attributes.carbon.carbonTotal')}</RowTextLeft>
                        <RowTextRight>
                          {StringUtils.formatNumber(material.carbonTotal)} kgCO2/UF
                        </RowTextRight>
                      </RowDetail>
                    )}
                    {material.tracksOfReuse && (
                      <RowDetail
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1.5}>
                        <RowTextLeft>{t('materials:attributes.carbon.tracksOfReuse')}</RowTextLeft>
                        <RowTextRight>{material.tracksOfReuse}</RowTextRight>
                      </RowDetail>
                    )}
                  </>
                )}
              </>
            )}
            {material.type === MaterialType.resource &&
              (material?.retrieval?.retrievalModality ||
                material.state ||
                material.conditioning ||
                material?.retrieval?.startDate ||
                material?.retrieval?.endDate) && (
                <>
                  <RowsTitle>{t('materials:attributes.retrieval.title')}</RowsTitle>
                  {material?.retrieval?.retrievalModality && (
                    <RowDetail
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.retrieval.modality')}</RowTextLeft>
                      <RowTextRight>
                        {t(`global:retrievalModality.${material.retrieval.retrievalModality}`)}
                      </RowTextRight>
                    </RowDetail>
                  )}
                  {material.state && (
                    <RowDetail
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.state')}</RowTextLeft>
                      <RowTextRight>{t(`materials:state.${material.state}`)}</RowTextRight>
                    </RowDetail>
                  )}
                  {material.conditioning && (
                    <RowDetail alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.conditioning')}</RowTextLeft>
                      <RowTextSeveralLines>{material.conditioning}</RowTextSeveralLines>
                    </RowDetail>
                  )}
                  {(material?.retrieval.startDate || material?.retrieval.endDate) && (
                    <RowDetail
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1.5}>
                      <RowTextLeft>{t('materials:attributes.retrieval.startDate')}</RowTextLeft>
                      <RowTextRight>
                        {t('global:format.date', {
                          date: new Date(material?.retrieval.startDate || 'invalid date'),
                        })}{' '}
                        -{' '}
                        {t('global:format.date', {
                          date: new Date(material?.retrieval.endDate || 'invalid date'),
                        })}
                      </RowTextRight>
                    </RowDetail>
                  )}
                </>
              )}
            {!!material.files?.length && !isPublic && (
              <Box mt="20px">
                <Documents
                  label={t('materials:attributes.files')}
                  readOnly={true}
                  documents={material.files}
                />
              </Box>
            )}
            {!!material.imageFiles?.length && (
              <Box mt="20px">
                <Documents
                  label={t('materials:attributes.imageFiles')}
                  readOnly={true}
                  documents={material.imageFiles}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
      {!isPublic ? (
        <></>
      ) : (
        <DialogActions sx={{ marginBottom: '30px', padding: '0 10px' }}>
          <AddToCart
            addToCart={addToCart}
            minQuantity={material.minQuantity}
            sellByQuantityOf={material.sellByQuantityOf}
            currentQty={material.currentQty}
            unit={material.unit}
          />
        </DialogActions>
      )}
    </Modal>
  )
}

export default MaterialDetailsModal
